<template>
  <b-card no-body>
    <SidebarSearch :components="searchComponent" @search="searchData" />

    <TableHeader
      :selected-length="selectedDatas.length"
      :per-page="perPage"
      :has-create-button="false"
      :disable-delete="true"
      :disable-filter="(isLoading || isFetching) && tableData.length === 0"
      @per-page-change="perPage = $event"
    >
    </TableHeader>

    <b-skeleton-table
      v-if="isLoading"
      :rows="4"
      :columns="7"
      :table-props="{ borderless: true, striped: true, small: true }"
    />

    <b-table-simple v-else responsive striped small style="zoom: 83%">
      <b-thead>
        <b-tr>
          <b-th class="bg-transparent" style="width: 6%">
            <b-form-checkbox
              v-model="selectAll"
              size="md"
              @change="selectAllRows"
            />
          </b-th>
          <b-th
            v-for="(column, index) in tableColumns"
            :key="index"
            :class="[column.class]"
            :style="{ width: column.style }"
          >
            {{ column.label }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="tableData.length > 0">
        <b-tr
          v-for="(data, index) in tableData"
          :key="index"
          :style="
            data.productList.length < 1
              ? 'opacity: 0.5; pointer-events: none; user-select: none; border: 2px dashed red; background-color: #f8d7da; cursor: not-allowed;'
              : ''
          "
        >
          <b-td>
            <b-form-checkbox v-model="selectedDatas" :value="data" size="md" />
          </b-td>
          <b-td>
            <b-img
              :src="
                require('@/assets/images/marketplace/' +
                  data.marketplaceType +
                  '.png')
              "
              :alt="data.marketplaceName"
              style="width: 24px; height: 24px"
            />
            {{ data.marketplaceName ? data.marketplaceName : 'Toko Dummy' }}
          </b-td>
          <b-td>
            <div class="d-flex flex-column">
              <div class="my-1">
                <b-badge variant="primary" class="mr-1">
                  {{
                    marketplaceAbbreviation(data.marketplaceType) +
                    '-' +
                    (data.orderId ? data.orderId : '-')
                  }}
                </b-badge>
                <b-badge class="mr-1">
                  {{ formatDateWithTime(data.createdDate) }}
                </b-badge>
              </div>
              <div>
                <div class="d-flex flex-column">
                  <b> List Produk :</b>
                  <b-table-simple borderless>
                    <b-tbody>
                      <b-tr
                        v-for="(product, productIndex) in data.productList"
                        :key="productIndex"
                      >
                        <b-td>
                          <div class="d-flex flex-column">
                            <div class="d-flex align-items-center">
                              <b-img
                                width="60"
                                :src="
                                  product.image
                                    ? product.image
                                    : 'https://cdn.statically.io/avatar/' +
                                      product.productName
                                "
                                :alt="'product #' + productIndex"
                                thumbnail
                                class="mr-2"
                              />
                              <div class="flex-grow-1">
                                <h6 class="text-primary">
                                  <b>{{ product.productName }}</b>
                                </h6>

                                <div class="d-flex flex-column mb-1">
                                  <!-- productId -->
                                  <small>
                                    <b> ID Produk :</b>
                                    {{
                                      product.productId
                                        ? product.productId
                                        : '-'
                                    }}
                                  </small>
                                  <!-- SKU -->
                                  <small>
                                    <b>SKU :</b>
                                    {{ product.sku ? product.sku : '-' }}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div class="d-flex flex-column">
                    <span class="line-dashed" />
                    <p class="mt-1 mb-0 p-0">
                      Nama Penerima :
                      {{ data.recipientName ? data.recipientName : '-' }}
                    </p>
                    <p class="m-0 p-0">
                      Nomor Resi :
                      {{ data.trackingNumber ? data.trackingNumber : '-' }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </b-td>
          <b-td class="text-right">
            {{ formattedCurrency(data.totalPrice) }}
          </b-td>
          <b-td class="text-center">{{ formattedNumber(data.quantity) }}</b-td>
          <b-td class="text-center">{{ data.courierName }}</b-td>
        </b-tr>
      </b-tbody>
      <EmptyTableRow v-else :colspan="tableColumns.length + 1" />
    </b-table-simple>

    <div v-if="isFetching" class="p-2">
      <b-skeleton-table
        :rows="4"
        :columns="7"
        :table-props="{ borderless: true, striped: true, small: true }"
      />
    </div>
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import orderMixins from '../mixins/orderMixins';

export default defineComponent({
  mixins: [orderMixins],
  data() {
    return {
      orderStatus: 'CANCEL',
      tableColumns: [
        {
          label: 'nama toko',
          class: 'bg-transparent text-center',
          style: '15%',
        },
        {
          label: 'Produk dan Data Pesanan',
          class: 'bg-transparent text-center',
          style: '50%',
        },
        {
          label: 'Total Harga',
          class: 'bg-transparent text-center',
          style: '',
        },
        {
          label: 'QTY',
          class: 'bg-transparent text-center',
          style: '5%',
        },
        { label: 'Ekspedisi', class: 'bg-transparent', width: '15%' },
      ],
    };
  },
});
</script>

<style lang="scss">
.line-dashed {
  border-bottom: 1px dashed #e0e0e0;
}
</style>
